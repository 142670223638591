import { useState, useEffect, useRef } from "react";
import { BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid } from "recharts";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import { get24HrTicks, get36HrTicks } from "./AiSleepViewTool";
import useDimensions from "react-use-dimensions";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";
import { filterSleepData } from "./SleepWakeTool";
import { GRID_GAP_DASH_ARRAY, GRID_STROKE_COLOR } from "../../ui/templates";

const mapToActivityCount = (sleepData) => {
  const _data = sleepData.map((x, i) => {
    return {
      activity_count: x.activity_count,
      sleepState: x.sleep_state,
      sleep_q: x.sleep_q,
      date: x.date,
      date_without_tz: x.date_without_tz,
      timestamp: x.timestamp,
    };
  });
  return _data;
};

export default function SleepActivityCountTool(props) {
  const { sleepData = [], date, uuid, loading = false } = props;
  const [ref, { x, y, width, height }] = useDimensions();
  const [activityCount, setActivityCounts] = useState([]);

  useEffect(() => {
    console.log("sleepdata", sleepData);
    const _filteredData = filterSleepData(sleepData);
    const _activityCountData = mapToActivityCount(_filteredData);
    console.log("_activityCountData", _activityCountData);

    setActivityCounts(_activityCountData);
  }, [sleepData, date, uuid]);

  let ticksNumber = 24;
  // const ticks24Hrs = get24HrTicks(12, date);
  let xticks = [];

  function get24HrTicks(ticksNumber, date) {
    const dateFrom = moment(date);
    let twelveHoursBefore = dateFrom.subtract(12, "hours"); // go back 12 hours to construct ticks

    const interval = Math.round(24 / ticksNumber);

    let ticks = Array.from({ length: ticksNumber + 1 }).map((x, i) => ({
      left: (100.0 * i) / ticksNumber,
    }));

    ticks = ticks.map((x, i) => {
      const tickObj = {
        ...x,
        t: twelveHoursBefore.utc().valueOf(),
        verbal: twelveHoursBefore.format("DD_HH:mm a z"),
      };
      twelveHoursBefore.add(interval, "hours");
      return twelveHoursBefore.utc().valueOf();
    });
    return ticks;
  }

  xticks = get24HrTicks(12, date);
  console.log(xticks);

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        showTopImg={false}
        message={`Sleep activity count data loading...`}
      />
    );
  }
  console.log("activityCountactivityCount", activityCount);

  return (
    <Wrapper style={{ height: 200, width: "100%"}} ref={ref}>
      {!sleepData || sleepData.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there no activity count data for this day."}
        />
      ) : (
        <div style={{ height: height }} className="activity-count-container">
          <BarChart height={height} width={width} data={activityCount}>
            <CartesianGrid strokeDasharray={GRID_GAP_DASH_ARRAY} />
            <XAxis
              dataKey="timestamp"
              ticks={xticks}
              // activity_count
              // tickCount={25}
              type="number"
              tickFormatter={(x) => {
                return moment(x).format("DD dd HH:mm");
              }}
              domain={([min, max]) => {
                return [min, max];
              }}
            />
            <YAxis
              dataKey={"activity_count"}
              domain={([min, max]) => {
                return [min, max];
              }}
            />
            <Bar dataKey="activity_count" fill="#3763f5" />
            <Tooltip />
            {/* <Legend /> */}
          </BarChart>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
