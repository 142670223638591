import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";

function get24HrTicks(ticksNumber, date) {
  const dateFrom = moment(date);
  let twelveHoursBefore = dateFrom.subtract(12, "hours"); // go back 12 hours to construct ticks
  const interval = Math.round(24 / ticksNumber);

  let ticks = Array.from({ length: ticksNumber + 1 }).map((x, i) => ({
    left: (100.0 * i) / ticksNumber,
  }));
  ticks = ticks.map((x, i) => {
    const tickObj = {
      ...x,
      t: twelveHoursBefore.utc().valueOf(),
      verbal: twelveHoursBefore.format("DD_HH:mm a"),
    };
    twelveHoursBefore.add(interval, "hours");
    return tickObj;
  });
  return ticks;
}

export default function SleepViewTool(props) {
  const {
    slots = [],
    vertAxisLabels = ["DEEP", "LIGHT", "REM", "AWAKE"],
    ticksNumber = 12,
    noDataText = "...",
    displayFullDay = false,
    loading,
    date,
    dd,
    uuid,
  } = props;
  const d = new Date();
  const diff = Math.round(d.getTimezoneOffset() / 60);
  const [tz, setTz] = useState(-diff);

  const useTimezone = false;

  useEffect(() => {
    // if (!useTimezone) {
    //   return;
    // }
    let mounted = true;
    // this hook is for knowing the timezone of the user
    const from = +moment(date).clone().subtract(1, "hours");
    const to = +moment(date).clone().add(1, "hours");
    DoctorAPI.getPatientRriSleep(uuid, from, to)
      .then((data) => {
        console.log("sleep-measurements-continuous/sleep-data", data);
        if (data.length > 0 && mounted) {
          const _tz =
            moment.parseZone(data[data.length - 1]?.date).utcOffset() / 60;
          setTz(_tz);
        }
      })
      .catch((e) => {
        console.error(e);
      });
    // const apiTemplate2 = `https://api.study-integration.corsano.com/v2/ds/users/{id}/sleep-measurements-continuous/sleep-data?timestamp_from={fromDate}&timestamp_to={toDate}`;
    // // console.log("apiTemplate2", apiTemplate2, uuid);

    // const _api = apiTemplate2
    //   .replace("{fromDate}", from.valueOf())
    //   .replace("{toDate}", to.valueOf())
    //   .replace("{id}", uuid);
    // fetch(_api)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("sleep-measurements-continuous/sleep-data", data);

    //     if (data.length > 0 && mounted) {
    //       const _tz =
    //         moment.parseZone(data[data.length - 1]?.date).utcOffset() / 60;
    //       setTz(_tz);
    //     }
    //   })
    //   .catch((error) => console.error(error));
    return () => (mounted = false);
  }, [uuid, date]);

  if (slots == undefined || slots.length == 0) {
    return null;
  }
  // console.log(
  //   `(${dd}) raw sleep slots from`,
  //   slots[0]?.start_date,
  //   slots[0]?.timestamp
  // );
  // console.log(
  //   `(${dd}) raw sleep slots until`,
  //   slots[slots.length - 1]?.start_date
  // );
  // console.log(`(${dd}) raw sleep slots `);
  // console.log(slots);

  const optSlots = displayFullDay
    ? TarnsformerHelper.getGroupedSleepPointsFullDay(
        slots,
        date,
        dd === "nt" ? tz + diff : 0
      )
    : TarnsformerHelper.getGroupedSleepPoints(slots);

  // console.log(`(${dd}) processed raw sleep slots:`);
  // console.log(optSlots);

  if (optSlots.length == 0) {
    return (
      <NoDataPlaceholder>
        <NoDataText>{noDataText}</NoDataText>
      </NoDataPlaceholder>
    );
  }
  let ticks;

  if (displayFullDay) {
    ticks = get24HrTicks(ticksNumber, date);
  } else {
    ticks = Array.from({ length: ticksNumber + 1 }).map((x, i) => ({
      left: (100.0 * i) / ticksNumber,
    }));
    const t0 = optSlots[0].from;
    let w = Math.round(
      1.0 * (optSlots[optSlots.length - 1].to - optSlots[0].from)
    );
    ticks = ticks.map((x) => ({
      ...x,
      t: t0 + Math.round((w * x.left) / 100.0),
    }));
  }

  return (
    <Wrapper className="sleep-stages-wrapper">
      {/* <span>
        {"Length:"  + slots.length}
        <br></br>
        {("raw sleep slots from", slots[0]?.start_date)}
        <br />
        {slots[0]?.timestamp}
        <br></br>
        {("until", slots[slots.length - 1]?.start_date)}
        <br></br>
        {(slots[slots.length - 1]?.timestamp)}
      </span> */}
      <LeftPlaceholder>
        {/* <div>tz: {tz}</div> */}
        <LeftInnerPlaceholder>
          {vertAxisLabels.map((x, i) => (
            <VertAxisLabel
              className="sleep-vert-label"
              key={`${x}`}
              height={+i + 1}
            >
              {x}
            </VertAxisLabel>
          ))}
        </LeftInnerPlaceholder>
      </LeftPlaceholder>
      <RightPlaceholder>
        <TopPlaceholder className="sleep-top-placeholder">
          <ChartPlaceholder>
            {[1, 2, 3, 4].map((x) => (
              <HorLine key={x} height={x} />
            ))}
            {optSlots.map((opt, i) => {
              return <Box {...opt} key={`opt_${i}_${opt.width_percent}`}></Box>;
            })}
          </ChartPlaceholder>
        </TopPlaceholder>

        <BottomPlaceholder className="sleep-bottom-placeholder">
          {ticks.map((x, i) => {
            return (
              <Tick key={i} left={x.left}>
                <TickInner className="sleep-tick-label">
                  {moment(x.t).format("DD dd HH:mm")}
                </TickInner>
              </Tick>
            );
          })}
        </BottomPlaceholder>
      </RightPlaceholder>
      {/*{JSON.stringify(optSlots)}*/}
    </Wrapper>
  );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = "lightgrey";

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${(props) => `${props.left}%`};
`;

const TickInner = styled.div`
  width: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  top: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${(props) => `calc(${(100.0 * props.height) / 4.0}% - 1px)`};
  z-index: 1;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${(props) => `${(100.0 * props.height) / 4.0}%`};
  width: ${(props) => `${props.width_percent}%`};
  left: ${(props) => `${props.left_percent}%`};
  box-sizing: border-box;
  background: ${(props) => props.color};
  z-index: 3;
`;

const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${(props) =>
    `calc(${(100.0 * props.height) / 4.0}% - 10px + ${bottomHeight}px)`};
  z-index: 1;
  :last-of-type {
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
`;
